import React from "react";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  Paper,
  Button,
  Grid,
  FormControl,
  TextField,
  Table,
  IconButton,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Typography,
  FormHelperText,
  InputAdornment,
  LinearProgress,
  Divider,
  Container,
} from "@material-ui/core";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactSelect from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import { Add, Delete, Search, Today } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
//
import { restrict } from "../../../component/restrict";
import axiosInstance from "../../../api/Instance";
import InventoryInstance from "../../../api/InventoryInstance";
import "../style.css";
import { useTheme } from "@material-ui/core/styles";
import useTable from "../../../component/useTable";
import { StyledTableRow, StyledTableCell } from "../utils";
import { SHOW_SNACKBAR } from "../../../actions/Type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
import moment from "moment";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Tooltip from "@material-ui/core/Tooltip";
import Controls from "../../../component/controls/Controls";
import { faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
let headCellsDraft = [
  { id: "id", label: "Sl. No." },
  { id: "stockId", label: "Stk Adj ID" },
  { id: "approvalDate", label: "Approval Date" },
  { id: "approvedBy", label: "Approved By" },
  { id: "date", label: "Date" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: 16,
    marginBottom: 16,
  },
  btn_height: {
    width: 24,
    height: 24,
    textTransform: "capitalize",
  },
  select: {
    minWidth: 180,
    marginTop: 3,
  },
  minWidth: {
    minWidth: 120,
  },
  margin: {
    padding: 15,
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
  },
  btnPending: {
    backgroundColor: "rgb(231 108 25)",
    fontSize: 12,
    height: 20,
    color: "white",
    minWidth: 100,
  },
  customDate: {
    height: "35px",

    borderRadius: 4,
    border: "1px solid rgba(224, 224, 224, 1)",

    width: "142px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  inputFont: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#474444 !important",
    "&.Mui-disabled": {
      backgroundColor: "#f5f7f5",
      color: "black",
    },
  },
  buttonColor: {
    "&.Mui-selected": {
      backgroundColor: "#B1E9AF",
      color: "black",
    },

    "&:hover": {
      backgroundColor: "#B1E9AF !important",
      color: "black",
    },
  },
  buttonColor1: {
    "&.Mui-selected": {
      backgroundColor: "#E9AFAF",
      color: "black",
    },
    "&:hover": {
      backgroundColor: "#E9AFAF !important",
      color: "black",
    },
  },
  alignRight: {
    textAlign: "right",
  },
}));
const customStyles = {
  // For the select itself (not the options)
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? "#f5f7f5 " : "unset",
      opacity: 1,
    };
  },
  option: (styles, { data }) => {
    // to change the bg color of essential medicines
    return {
      ...styles,
      backgroundColor: data.isEssential ? "#dafed6" : "white",
      color: "black",
      ":hover": {
        backgroundColor: styles.backgroundColor,
      },
    };
  },
  singleValue: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#474444 " : "#474444",
      opacity: 1,
    };
  },
};
const drugTypeSchema = yup.object().shape({
  id: yup.string(),
  typeName: yup.string(),
});

const drugNameSchema = yup.object().shape({
  id: yup.string(),
  MedicineName: yup.string(),
  uomName: yup.string(),
});

const serialNoSchema = yup.object().shape({
  id: yup.string(),
  serialNo: yup.string(),
});

const arraySchema = yup.object().shape({
  drugType: drugTypeSchema.nullable().required("Type is Required"),
  drugName: drugNameSchema.nullable().required("Name is required"),
  // singleUnit: yup.string().required("Unit is required").matches(/^[1-9][0-9]*$/, "Can not start with 0"),
  // expDate: yup.string().required("This field is required"),
  // mfgDate: yup.string().required("This field is required"),
  adjustedQty: yup
    .number()
    .typeError("Required")
    .nullable()
    .when("adjustedQtyInitial", (adjustedQtyInitial) => {
      if (adjustedQtyInitial === 0) {
        return yup
          .string()
          .required("Required")
          .matches(/^[1-9][0-9]*$/, "Can not start with 0");
      } else {
        return yup.string().notRequired();
      }
    }),
  available: yup.number(),
  reAdjust: yup.string().when("adjustedQtyInitial", (adjustedQtyInitial) => {
    if (adjustedQtyInitial !== 0) {
      return yup
        .string()
        .required("Required")
        .matches(/^[1-9][0-9]*$/, "Can not start with 0");
    } else {
      return yup.string().notRequired();
    }
  }),
  serialBatchNo: serialNoSchema.nullable().required("Batch is required"),

  reason: yup.string().when("newSplLevel", (newSplLevel) => {
    if (newSplLevel !== "") {
      return yup.string().required("Reason is required");
    }
  }),
  // newSplLevel
});

const schema = yup.object().shape({
  array: yup
    .array()
    .of(arraySchema)
    .required("Must have fields"),
  // department: yup.string().nullable().required("Department is required"),s
});
const [W, H] = [210, 297];
const margin = 10;

const style = {
  cellPadding: 1, // a number, array or object (see margin below)
  fontSize: 8,
  font: "helvetica", // helvetica, times, courier
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal", // normal, bold, italic, bolditalic
  overflow: "linebreak", // visible, hidden, ellipsize or linebreak
  fillColor: false, // false for transparent or a color as described below
  textColor: 0,
  halign: "left", // left, center, right
  valign: "middle", // top, middle, bottom
  cellWidth: "auto", // 'auto', 'wrap' or a number
};

const generatePdf = (report, data, staffData, filterData) => {
  const org = staffData.orgName;

  const now = moment(new Date()).format("DD-MM-YYYY");
  const time = new Date().toLocaleTimeString();

  const pdfData = data.stockAdjustmentDetailsDtos.map((item) => [
    item.drugTypeName,
    item.drugName,
    item.uomName,
    item.serialNo,
    moment(item.manufactureDate).format("DD-MM-YYYY"),
    moment(item.expiryDate).format("DD-MM-YYYY"),
    item.adjustedQty,
    item.readjustQty,
    item.availableQty,
    item.approvalStatus !== "" ? item.approvalStatus : "N/A",
  ]);

  const pdf = new jsPDF("p", "mm", [W, H], true);
  let Y = margin;
  pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
  pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
  pdf.setFontSize(14);
  let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
  pdf.text(splitTitle, W / 2, Y, { align: "center" });
  pdf.setFontSize(13);
  pdf.text(report, W / 2, Y + 15, "center");
  Y += 30;
  pdf.setFontSize(10);

  pdf.text(`Stock ID.: ${data.stockAdjNo}`, margin, Y);
  pdf.text(
    `Status.: ${
      data.approvalStatusName === "Pending" ? "Pending" : "Completed"
    }`,
    margin,
    Y + 6
  );

  pdf.text(
    `Verified By.: ${
      filterData[0].approvedByName === undefined
        ? "N/A"
        : filterData[0].approvedByName
    }`,
    margin,
    Y + 12
  );
  pdf.text(
    `Verified On: ${
      data.approvalDatetime === undefined
        ? "N/A"
        : moment(data.approvalDatetime).format("MM-DD-YYYY")
    }  `,
    W - margin,
    Y + 12,
    "right"
  );

  Y += 16;

  pdf.autoTable({
    head: [
      [
        { content: "Drug Type" },
        { content: "Drug Name" },
        { content: "UoM" },

        { content: "Serial/Batch No." },
        { content: "Mfg. Date" },
        { content: "Exp.date" },

        { content: "Adjust" },
        { content: "Readjust" },
        { content: "Available" },
        { content: "Status" },
      ],
    ],

    body: pdfData,
    // foot:[
    //   [{ content: `Total Amount.: ${data.totalBillAmount}`, colSpan: 8, styles: { halign: 'right', fillColor: [255,255,255],textColor:[0,0,0]} }],
    // ],
    startY: Y,
    margin,
    headStyles: {
      ...style,
      fillColor: [11, 156, 171],
      textColor: 255,
      lineColor: 255,
      fontSize: 8,
      fontWeight: 500,
    },
    bodyStyles: { ...style },
  });

  pdf.save(`Stock Adjustment Report-${now}, ${time}.pdf`);
};

const StockAdjustmentRequest = () => {
  const MUItheme = useTheme();
  const reactSelectTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: MUItheme.palette.primary.main,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.loginReducer.staffLogin.orgId);
  const staffId = useSelector((state) => state.loginReducer.staffLogin.staffId);
  const staff = useSelector((state) => state.loginReducer.staffLogin);
  const [batchNo, setBatchNo] = React.useState([]);
  const storeId = useSelector((state) => state.loginReducer.staffLogin.storeId);

  const somId = useSelector((state) => state.loginReducer.staffLogin.somId);
  const defaultArray = {
    drugType: "",
    drugTypeName: "",
    drugName: "",
    singleUnit: "",
    uom: "",
    mfgDate: "",
    expDate: "",
    adjustedQty: "",
    reAdjust: "",
    available: "",
    reason: "",
    remark: "",
    serialBatchNo: "",
    approvalStatus: "",
    id: 0,
    newSplLevel: "",
  };

  const defaultValues = {
    array: [defaultArray],
    department: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    setError,
    control,
    formState,
    clearErrors,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "array",
  });

  const [drugTypes, setDrugTypes] = React.useState([]);
  const [drugNames, setDrugNames] = React.useState([]);

  const [filterFnDraft, setFilterFnDraft] = React.useState({
    fn: (items) => items,
  });

  const [recordsDraft, setRecordsDraft] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);

  const [viewMode, setViewMood] = React.useState(false);
  const myOwnColor = useSelector((state) => state.MainReducer.selectedColor);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [meds, setMeds] = React.useState([]);
  const [pointerEvent, setPointerNone] = React.useState(false);
  const [approvalStatus, setApprovalStatus] = React.useState("");
  //Draft
  const {
    TblContainer,
    TblHead: TblHeadDraft,
    TblPagination: TblPaginationDraft,
    recordsAfterPagingAndSorting: recordsAfterPagingAndSortingDraft,
  } = useTable(recordsDraft, headCellsDraft, filterFnDraft);
  //Submitted

  const getDrugType = () => {
    axiosInstance
      .get("medicineType/getAllMedicineTypeBySom/" + somId)
      .then((response) => {
        setDrugTypes(response.data);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const getDrugNames = (selectedObj) => {
    axiosInstance
      .get("medicineName/getByMedTypeNOrgId/" + selectedObj.id + "/" + orgId)
      .then((res) => {
        setDrugNames((ps) => ({ ...ps, [selectedObj.id]: res.data }));
        console.log(drugNames);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const fetchStockAdjustment = () => {
    setLoading(true);
    InventoryInstance.get(
      `stockAdjustment/fetchByOrgIdStoreId/${orgId}/${storeId}`
    )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setRecordsDraft(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const disablesPast = () => {
    var today = new Date();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    var yy = today.getFullYear();
    if (mm < 10) {
      mm = "0" + mm.toString();
    }
    if (dd < 10) {
      dd = "0" + dd.toString();
    }
    return yy + "-" + mm + "-" + dd;
  };

  React.useEffect(() => {
    getDrugType();
    fetchStockAdjustment();
    disablesPast();
  }, []);

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [formState, isSubmitSuccessful]);

  const handleSearchDraft = (e) => {
    let val = e.target.value;
    setFilterFnDraft({
      fn: (items) => {
        if (val === "") return items;
        return items.filter(
          (x) =>
            x.approvalStatusName?.toLowerCase().includes(val.toLowerCase()) ||
            x.stockAdjReqId?.toLowerCase().includes(val.toLowerCase()) ||
            x.createdDateString?.toLowerCase().includes(val.toLowerCase())
        );
      },
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onFormSubmit = (data) => {
    // for update

    const req = {
      stockAdjustmentDetailsDtos: data.array.map((el) => ({
        drugTypeId: +el.drugType.id,
        drugNameId: +el.drugName.id,
        serialNo: el.serialBatchNo.serialNo,
        manufactureDate: el.mfgDate,
        expiryDate: el.expDate,
        adjustedQty: +el.adjustedQty,
        readjustQty: +el.reAdjust,
        availableQty: +el.available,

        reason: el.reason,
        remarks: el.remark,
        stockAdjustmentId: 4,
        drugTypeName: el.drugType.typeName,
        drugName: el.drugName.MedicineName,
        uomName: el.uomName,

        singleUnit: el.singleUnit === "" ? null : +el.singleUnit,
      })),

      organizationId: orgId,
      stockAdjReqId: "",
      subStockStoreId: storeId,
      createdby: staffId,
      approvalStatusName: "Submitted",
      approvalStatus: 2,
    };
    console.log(req);
    InventoryInstance.post(`stockAdjustment/save`, req)
      .then(async (response) => {
        const res = response.data;
        if (res === "success") {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              msg: "Saved Successfully",
              openSnackbar: true,
            },
          });
          fetchStockAdjustment();
          reset(defaultValues);
          setValue("array", [defaultArray]);
          handleReset();
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              msg: "Failed to Save",
              openSnackbar: true,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something Went Wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const handleReset = () => {
    reset(defaultValues);
    setSelectedDate(new Date());
    setIsEditMode(false);
    setViewMood(false);
    setMeds([]);
  };

  const handleEdit = (e, id) => {
    setIsEditMode(true);

    setViewMood(true);
    InventoryInstance.get(`stockAdjustment/fetch/${orgId}/${id}`)
      .then((res) => {
        // getDrugNamesdummy(res.daa)
        setApprovalStatus(res.data?.approvalStatusName);
        reset({
          array: res.data.stockAdjustmentDetailsDtos.map((el) => ({
            ...el,
            drugType: drugTypes.find(
              (drugType) => +el.drugTypeId === +drugType.id
            ),
            remark: el.remarks,
            adjustedQty: el.adjustedQty,
            reAdjust: el.readjustQty,
            available: el.availableQty,
            mfgDate: moment(el.manufactureDate).format("YYYY-MM-DD"),
            expDate: moment(el.expiryDate).format("YYYY-MM-DD"),
            uom: el.uomName,
            serialBatchNo: el.serialNo,
          })),
        });
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something Went Wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const printDocument = (id) => {
    const reportName = "Stock Adjustment Report";

    const filterName = recordsDraft.filter((item) => item.id === id);
    console.log(filterName);
    InventoryInstance.get(`stockAdjustment/fetch/${orgId}/${id}`)
      .then((res) => {
        generatePdf(
          reportName,

          res.data,
          staff,
          filterName
        );
      })
      .catch(() => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            msg: "Something Went Wrong",
            openSnackbar: true,
          },
        });
      });
  };

  const getRelatedData = (drugData, drugNameData) => {
    InventoryInstance.get(
      `stockAdjustment/fetchSerialNo/${orgId}/${storeId}/${drugData?.id}/${drugNameData?.id}`
    )
      .then((res) => {
        setBatchNo((ps) => ({ ...ps, [drugNameData.id]: res.data }));
        console.log(batchNo);
        console.log(res.data);
      })
      .catch(() => {
        console.log("error");
      });
  };

  React.useEffect(() => {});

  const calCulateSpillage = (e, index) => {
    console.log(getValues());
    const getReadjust = getValues()?.array[index].reAdjust;
    const getadjust = getValues()?.array[index].adjustedQty;
    const getIssued = getValues()?.array[index].available;
    const getSpillage = getValues()?.array[index].remark;

    var totalPrice = +getReadjust + (getadjust * 100) / getIssued;

    if (totalPrice > getSpillage) {
      setValue(`array[${index}].newSplLevel`, totalPrice);

      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          msg: "Current SpillageLevel is higher",
          openSnackbar: true,
        },
      });
    } else {
      setValue(`array[${index}].newSplLevel`, "");
    }

    // });
  };

  const handleBlur = (e, index, fieldName) => {
    const getReadjust = +getValues()?.array[index].reAdjust;
    const getadjust = +getValues()?.array[index].adjustedQty;
    const getIssued = +getValues()?.array[index].available;

    var checkQuantity = getReadjust + getadjust;
    console.log(checkQuantity);
    if (getIssued !== "" && getadjust) {
      if (checkQuantity > getIssued) {
        if (fieldName === "reAdjust") {
          setError(`array[${index}].reAdjust`, {
            type: "custom",
            message: "Readjust and Adjust Qty Can not greater than issued Qty",
          });
          setPointerNone(true);
        } else if (fieldName === "adjustedQty") {
          setError(`array[${index}].adjustedQty`, {
            type: "custom",
            message: "Readjust and Adjust Qty Can not greater than issued Qty",
          });
          setPointerNone(true);
        }
      } else {
        setPointerNone(false);
        clearErrors(`array[${index}].reAdjust`);
      }
    }
    // setPointerNone(false)
  };
  return (
    <>
      {/* <ConcomitantMedicine/> */}
      <Paper className={classes.pageContent}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            component={Paper}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  className={`${classes.table} report-table`}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{
                        backgroundColor: myOwnColor,
                      }}
                    >
                      <StyledTableCell align="center" rowSpan="3">
                        Drug Type
                      </StyledTableCell>
                      <StyledTableCell align="center" rowSpan="3">
                        Drug Name
                      </StyledTableCell>
                      <StyledTableCell align="center" rowSpan="3">
                        UoM
                      </StyledTableCell>

                      <StyledTableCell align="center" rowSpan="3">
                        Serial/Batch No.
                      </StyledTableCell>
                      <StyledTableCell align="center" rowSpan="3">
                        Single Unit
                      </StyledTableCell>
                      <StyledTableCell align="center" rowSpan="3">
                        Mfg. Date
                      </StyledTableCell>
                      <StyledTableCell align="center" rowSpan="3">
                        Exp. Date
                      </StyledTableCell>
                      <StyledTableCell align="center" colSpan={3}>
                        Quantity
                      </StyledTableCell>
                      {viewMode ? (
                        <StyledTableCell align="center" rowSpan="3">
                          Status
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center" rowSpan="3">
                          Spillage level(%)
                        </StyledTableCell>
                      )}
                      {viewMode ? (
                        approvalStatus === "Pending" ? null : (
                          <StyledTableCell
                            align="center"
                            className={classes.inputFontThead}
                            rowSpan="3"
                          >
                            Approved Qty
                          </StyledTableCell>
                        )
                      ) : null}
                      <StyledTableCell align="center" rowSpan="3">
                        Reason
                      </StyledTableCell>

                      <StyledTableCell align="center" rowSpan="3">
                        <IconButton
                          disabled={viewMode}
                          onClick={() => {
                            append(defaultArray);
                          }}
                          style={{ color: "white", padding: 4 }}
                        >
                          <Add />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell align="center">Avl Qty</StyledTableCell>
                      <StyledTableCell align="center">Adjust</StyledTableCell>
                      <StyledTableCell align="center">Readjust</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((_, index) => {
                      return (
                        <TableRow key={index}>
                          <StyledTableCell
                            align="center"
                            className={classes.select}
                          >
                            <FormControl fullWidth>
                              {viewMode ? (
                                <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "14px" }}>
                                      {getValues()?.array[index]?.drugTypeName}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <TextField
                                    style={{ width: "200", fontSize: "14px" }}
                                    {...register(
                                      `array[${index}].drugTypeName`
                                    )}
                                    placeholder="Drug Type Name"
                                    size="small"
                                    fullWidth
                                    disabled={viewMode}
                                    InputProps={{
                                      className: classes.inputFont,
                                    }}
                                    onInput={restrict.number}
                                    variant="outlined"
                                  />
                                </Tooltip>
                              ) : (
                                <FormControl fullWidth>
                                  <Controller
                                    name={`array[${index}].drugType`}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <ReactSelect
                                        styles={customStyles}
                                        theme={reactSelectTheme}
                                        openMenuOnClick={false}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        onChange={(val) => {
                                          onChange(val);
                                          setBatchNo([]);
                                          setValue(
                                            `array[${index}].drugName`,
                                            ""
                                          );
                                          if (val) {
                                            getDrugNames(val);
                                          }
                                          //  else {
                                          //   setDrugNames([]);
                                          // }
                                        }}
                                        value={value}
                                        // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                        menuPosition="fixed"
                                        options={drugTypes}
                                        getOptionLabel={(option) =>
                                          option.typeName
                                        }
                                        getOptionValue={(option) => option.id}
                                      />
                                    )}
                                  />
                                  <FormHelperText
                                    error={!!errors?.array?.[index]?.drugType}
                                  >
                                    {errors?.array?.[index]?.drugType?.message}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            </FormControl>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            className={classes.select}
                          >
                            <FormControl fullWidth>
                              {viewMode ? (
                                <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "14px" }}>
                                      {getValues()?.array[index]?.drugName}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <TextField
                                    style={{ width: "200", fontSize: "14px" }}
                                    {...register(`array[${index}].drugName`)}
                                    placeholder="Drug name"
                                    size="small"
                                    fullWidth
                                    disabled={viewMode}
                                    InputProps={{
                                      className: classes.inputFont,
                                    }}
                                    onInput={restrict.number}
                                    variant="outlined"
                                  />
                                </Tooltip>
                              ) : (
                                <FormControl fullWidth>
                                  <Controller
                                    name={`array[${index}].drugName`}
                                    control={control}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <ReactSelect
                                        theme={reactSelectTheme}
                                        styles={customStyles}
                                        openMenuOnClick={false}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        onChange={(val) => {
                                          onChange(val);
                                          const value = val?.id;
                                          const data = [...meds];

                                          data[index] = value;
                                          setMeds(data);
                                          setValue(
                                            `array[${index}].uomName`,
                                            getValues(
                                              `array[${index}].drugName.uomName`
                                            )
                                          );
                                          setValue(
                                            `array[${index}].serialBatchNo`,
                                            ""
                                          );

                                          setValue(
                                            `array[${index}].mfgDate`,
                                            ""
                                          );
                                          setValue(
                                            `array[${index}].expDate`,
                                            ""
                                          );
                                          setValue(
                                            `array[${index}].adjustedQtyInitial`,
                                            ""
                                          );
                                          setValue(
                                            `array[${index}].adjustedQty`,
                                            ""
                                          );
                                          setValue(
                                            `array[${index}].remark`,
                                            ""
                                          );
                                          setValue(
                                            `array[${index}].available`,
                                            ""
                                          );
                                          const drugData = getValues(
                                            `array[${index}].drugType`
                                          );

                                          const drugNameData = getValues(
                                            `array[${index}].drugName`
                                          );
                                          if (
                                            drugData !== "" &&
                                            drugNameData !== ""
                                          ) {
                                            getRelatedData(
                                              drugData,
                                              drugNameData
                                            );
                                          } else {
                                            setBatchNo([]);
                                          }
                                        }}
                                        value={value}
                                        isOptionDisabled={(option) =>
                                          meds?.includes(option.id)
                                        }
                                        // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                        menuPosition="fixed"
                                        options={
                                          drugNames[
                                            watch(`array[${index}].drugType.id`)
                                          ]
                                        }
                                        getOptionLabel={(option) =>
                                          option.MedicineName
                                        }
                                        getOptionValue={(option) => option.id}
                                      />
                                    )}
                                  />
                                  <FormHelperText
                                    error={!!errors?.array?.[index]?.drugName}
                                  >
                                    {errors?.array?.[index]?.drugName?.message}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            </FormControl>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              {...register(`array[${index}].uomName`)}
                              style={{ width: "100px" }}
                              placeholder="UOM"
                              size="small"
                              fullWidth
                              inputProps={{
                                className: classes.inputFont,
                              }}
                              variant="outlined"
                              disabled
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            className={classes.select}
                          >
                            {viewMode ? (
                              <Tooltip
                                title={
                                  <Typography style={{ fontSize: "14px" }}>
                                    {getValues()?.array[index]?.serialBatchNo}
                                  </Typography>
                                }
                                arrow
                              >
                                <TextField
                                  {...register(`array[${index}].serialBatchNo`)}
                                  disabled
                                  InputProps={{
                                    className: classes.inputFont,
                                  }}
                                  placeholder="Batch No."
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                />
                              </Tooltip>
                            ) : (
                              <FormControl fullWidth>
                                <Controller
                                  name={`array[${index}].serialBatchNo`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <ReactSelect
                                      theme={reactSelectTheme}
                                      openMenuOnClick={false}
                                      maxMenuHeight={200}
                                      isClearable={true}
                                      onChange={(val) => {
                                        onChange(val);

                                        setValue(
                                          `array[${index}].mfgDate`,
                                          getValues(
                                            `array[${index}].serialBatchNo.manufactureDate`
                                          )
                                        );
                                        setValue(
                                          `array[${index}].expDate`,
                                          getValues(
                                            `array[${index}].serialBatchNo.expiryDate`
                                          )
                                        );
                                        setValue(
                                          `array[${index}].available`,
                                          getValues(
                                            `array[${index}].serialBatchNo.issuedQty`
                                          )
                                        );
                                        setValue(
                                          `array[${index}].adjustedQty`,
                                          getValues(
                                            `array[${index}].serialBatchNo.adjustedQty`
                                          )
                                        );
                                        setValue(
                                          `array[${index}].adjustedQtyInitial`,
                                          getValues(
                                            `array[${index}].serialBatchNo.adjustedQty`
                                          )
                                        );
                                        setValue(
                                          `array[${index}].remark`,
                                          getValues(
                                            `array[${index}].serialBatchNo.splillageLevel`
                                          )
                                        );
                                      }}
                                      value={value}
                                      // isDisabled={
                                      //   watch(`array[${index}].drugType.id`,`array[${index}].drugName.id,${storeId}`) ===
                                      //   ""
                                      // }
                                      // isOptionDisabled={option => array.map(el => el.symptomName).includes(option.signSymptomName)}
                                      menuPosition="fixed"
                                      options={
                                        batchNo[
                                          watch(`array[${index}].drugName.id`)
                                        ]
                                      }
                                      getOptionLabel={(option) =>
                                        option.serialNo
                                      }
                                      getOptionValue={(option) =>
                                        option.serialNo
                                      }
                                    />
                                  )}
                                />
                                <FormHelperText
                                  error={
                                    !!errors?.array?.[index]?.serialBatchNo
                                  }
                                >
                                  {
                                    errors?.array?.[index]?.serialBatchNo
                                      ?.message
                                  }
                                </FormHelperText>
                              </FormControl>
                            )}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <TextField
                              {...register(`array.[${index}].singleUnit`)}
                              style={{ width: "100px" }}
                              disabled={viewMode}
                              onInput={restrict.digits}
                              placeholder="Single Unit"
                              size="small"
                              fullWidth
                              inputProps={{
                                className: viewMode
                                  ? `${classes.alignRight} ${classes.inputFont}`
                                  : `${classes.inputFont}`,
                                maxLength: 7,
                              }}
                              variant="outlined"
                              error={!!errors?.array?.[index]?.singleUnit}
                              helperText={
                                errors?.array?.[index]?.singleUnit?.message
                              }
                            />
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <TextField
                              {...register(`array[${index}].mfgDate`)}
                              placeholder="Required Quantity"
                              type="date"
                              size="small"
                              fullWidth
                              disabled
                              onKeyDown={(e) => e.preventDefault()}
                              variant="outlined"
                              inputProps={{
                                className: classes.inputFont,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              {...register(`array[${index}].expDate`)}
                              placeholder="Exp. Date"
                              type="date"
                              size="small"
                              fullWidth
                              onKeyDown={(e) => e.preventDefault()}
                              variant="outlined"
                              inputProps={{
                                className: classes.inputFont,
                                min: disablesPast(),
                              }}
                              disabled
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <TextField
                              {...register(`array[${index}].available`)}
                              style={{ width: "100px" }}
                              placeholder="Issued Qty"
                              onInput={restrict.digits}
                              size="small"
                              fullWidth
                              disabled
                              variant="outlined"
                              inputProps={{
                                className: `${classes.alignRight} ${classes.inputFont}`,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Controller
                              name={`array[${index}].adjustedQty`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  value={value}
                                  style={{ width: "100px" }}
                                  placeholder="Adjust"
                                  onInput={restrict.digits}
                                  size="small"
                                  fullWidth
                                  // disabled
                                  variant="outlined"
                                  required
                                  onChange={(e, val) => {
                                    onChange(e, val);
                                    calCulateSpillage(e, index);
                                  }}
                                  inputProps={{
                                    className: viewMode
                                      ? `${classes.alignRight} ${classes.inputFont}`
                                      : `${classes.inputFont}`,
                                    maxLength: 7,
                                  }}
                                  disabled={
                                    watch(
                                      `array[${index}].adjustedQtyInitial`
                                    ) !== 0
                                  }
                                  onBlur={(e) => {
                                    handleBlur(e, index, "adjustedQty");
                                  }}
                                  error={!!errors?.array?.[index]?.adjustedQty}
                                  helperText={
                                    errors?.array?.[index]?.adjustedQty?.message
                                  }
                                />
                              )}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Controller
                              name={`array[${index}].reAdjust`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  style={{ width: "100px" }}
                                  placeholder="Re Adjust"
                                  onInput={restrict.number}
                                  size="small"
                                  fullWidth
                                  inputProps={{
                                    className: viewMode
                                      ? `${classes.alignRight} ${classes.inputFont}`
                                      : `${classes.inputFont}`,
                                    maxLength: 7,
                                  }}
                                  onChange={(e, val) => {
                                    onChange(e, val);
                                    calCulateSpillage(e, index);
                                  }}
                                  disabled={
                                    watch(
                                      `array[${index}].adjustedQtyInitial`
                                    ) === 0
                                  }
                                  onBlur={(e) => {
                                    handleBlur(e, index, "reAdjust");
                                  }}
                                  value={value}
                                  // disabled={viewMode}
                                  variant="outlined"
                                  error={!!errors?.array?.[index]?.reAdjust}
                                  helperText={
                                    errors?.array?.[index]?.reAdjust?.message
                                  }
                                />
                              )}
                            />
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {viewMode ? (
                              <Controller
                                control={control}
                                name={`array[${index}].approvalStatus`}
                                render={({ field: { onChange, value } }) => (
                                  <ToggleButtonGroup
                                    disabled={viewMode}
                                    value={value}
                                    exclusive
                                    onChange={(e, val) => {
                                      onChange(val);

                                      setValue(
                                        `array[${index}].approvalStatus`,
                                        val
                                      );
                                    }}
                                    aria-label="toggle"
                                    size="small"
                                  >
                                    <ToggleButton
                                      value="approved"
                                      aria-label="left aligned"
                                      className={classes.buttonColor}
                                      style={{
                                        borderRadius: "10px 0px 0px 10px",
                                      }}
                                      disabled={viewMode}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        &nbsp;Approve&nbsp;
                                      </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                      value="reject"
                                      aria-label="centered"
                                      className={classes.buttonColor1}
                                      style={{
                                        borderRadius: "0px 10px 10px 0px",
                                      }}
                                      disabled={viewMode}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "10px",
                                        }}
                                      >
                                        &nbsp;&nbsp;Reject&nbsp;
                                      </Typography>
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                )}
                              />
                            ) : (
                              <TextField
                                {...register(`array[${index}].remark`)}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                InputProps={{
                                  className: classes.inputFont,
                                  maxLength: 100,
                                }}
                                disabled
                                placeholder="Spillage level(%)"
                                size="small"
                                fullWidth
                                onInput={restrict.alphanumericspace}
                                variant="outlined"
                              />
                            )}
                          </StyledTableCell>

                          {viewMode ? (
                            approvalStatus === "Pending" ? null : (
                              <StyledTableCell
                                align="center"
                                className={classes.inputFontThead}
                              >
                                <TextField
                                  {...register(`array[${index}].approvedQty`)}
                                  inputProps={{
                                    className: classes.inputFont,
                                  }}
                                  InputProps={{
                                    className: classes.inputFont,
                                  }}
                                  placeholder="Approved Qty"
                                  size="small"
                                  fullWidth
                                  disabled
                                  variant="outlined"
                                />
                              </StyledTableCell>
                            )
                          ) : null}
                          <StyledTableCell align="center">
                            <Tooltip
                              title={
                                <Typography style={{ fontSize: "14px" }}>
                                  {getValues()?.array[index]?.reason}
                                </Typography>
                              }
                              arrow
                            >
                              <TextField
                                {...register(`array[${index}].reason`)}
                                inputProps={{
                                  maxLength: 500,
                                  className: classes.inputFont,
                                }}
                                placeholder="Reason"
                                multiline
                                onInput={restrict.alphanumericspace}
                                maxRows={2}
                                size="small"
                                fullWidth
                                disabled={viewMode}
                                variant="outlined"
                                error={!!errors?.array?.[index]?.reason}
                                helperText={
                                  errors?.array?.[index]?.reason?.message
                                }
                              />
                            </Tooltip>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {index !== 0 && (
                              <IconButton
                                disabled={viewMode}
                                aria-label="delete"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            )}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <div>
                <span
                  className={classes.margin}
                  style={{ display: "inline-block" }}
                >
                  <Typography component={"span"}>Requested Date:</Typography>
                </span>
                <span className={classes.margin}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      disableToolbar
                      disablePast
                      autoOk
                      disabled
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="dense"
                      InputProps={{
                        endAdornment: (
                          <IconButton size="small">
                            <Today color="action" />
                          </IconButton>
                        ),
                      }}
                      size="small"
                      inputVariant="outlined"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </span>
                <span style={{ float: "right", marginTop: 10 }}>
                  <span className={classes.margin}>
                    <Button onClick={handleReset} variant="contained">
                      {viewMode ? "Close" : "Reset"}
                    </Button>
                  </span>

                  {viewMode ? null : (
                    <span className={classes.margin}>
                      <Button
                        onClick={handleSubmit((data) => onFormSubmit(data))}
                        variant="contained"
                        disable={pointerEvent}
                        style={{
                          backgroundColor: myOwnColor,
                          color: "#fff",
                          pointerEvents: pointerEvent ? "none" : "unset",
                        }}
                      >
                        Submit for Approval
                      </Button>
                    </span>
                  )}
                </span>
              </div>
            </Grid>
          </Grid>
        </form>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />

        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {/* Draft---Table */}

          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} align="right">
            <TextField
              size="small"
              variant="outlined"
              onChange={handleSearchDraft}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
              inputProps={{ maxLength: 50 }}
              label="Search"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
            <TableContainer style={{ maxHeight: "500px" }}>
              {loading && <LinearProgress />}
              <Table stickyHeader size="small">
                <TblHeadDraft />
                {!!recordsAfterPagingAndSortingDraft()?.length ? (
                  <TableBody>
                    {recordsAfterPagingAndSortingDraft()?.map((item, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell>{item.srNo}</StyledTableCell>
                        <StyledTableCell>{item.stockAdjNo}</StyledTableCell>
                        <StyledTableCell>
                          {"approvalDatetime" in item
                            ? moment(item.approvalDatetime).format("DD-MM-YYYY")
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {"approvedByName" in item
                            ? item.approvedByName
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.createdDateString}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Controls.Button
                            size="small"
                            className={
                              item.approvalStatusName === "Pending"
                                ? classes.btnPending
                                : item.approvalStatusName === "Approved"
                                ? classes.btnActive
                                : classes.btnInActive
                            }
                            text={
                              item.approvalStatusName === "Pending"
                                ? "Pending"
                                : "Completed"
                            }
                          ></Controls.Button>
                        </StyledTableCell>

                        <StyledTableCell>
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              reset(defaultValues);
                              handleEdit(e, item.id);
                            }}
                          >
                            <FontAwesomeIcon
                              title="View"
                              style={{ color: "#087683", fontSize: "13px" }}
                              icon={faEye}
                            />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              reset(defaultValues);
                              printDocument(item.id);
                            }}
                          >
                            <FontAwesomeIcon
                              title="Print Pdf"
                              style={{ color: "#f55140", fontSize: "15px" }}
                              icon={faFilePdf}
                            />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <StyledTableCell colSpan={7} align="center">
                        No Records Found
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TblPaginationDraft />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default StockAdjustmentRequest;
