import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  LinearProgress,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import { Field, reduxForm } from "redux-form";
// import "./master_css.css";
import moment from "moment";
import InventoryInstance from "../../../api/InventoryInstance";

import useTable from "../../../component/useTable";
// import Button from "../../../component/controls/Button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ahmis from "../../../assets/ahmis.png";
import leaf from "../../../assets/leaf.png";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#07A0B0",
    color: theme.palette.common.white,
    fontSize: "1rem",
    padding: 6,
  },
  body: {
    fontSize: 14,
    padding: 6,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const headCells = [];

function SubStockManagement(props) {
  const classes = useStyles();
  const orgId = useSelector((state) => state.loginReducer.staffLogin.orgId);
  const storeId = useSelector((state) => state.loginReducer.staffLogin.storeId);
  const [loading, setLoading] = useState(false);
  const myOwnColor = "#0B9CAB";
  const staff = useSelector((state) => state.loginReducer.staffLogin);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [records, setRecords] = useState([]);

  //link to fetch data initially
  const [isBufferRange, setIsBufferRange] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  // const [badgeCount, setBadgeCount] = useState(0);
  const [expiry, setExpiry] = useState(0);

  const handleSearch = (e) => {
    let value = e.target.value;
    setFilterFn({
      fn: (items) => {
        if (value === "") {
          return items;
        }
        return items.filter(
          (x) =>
            x.drugName?.toLowerCase().includes(value.toLowerCase()) ||
            x.drugTypeName?.toLowerCase().includes(value.toLowerCase()) ||
            x.serialNo?.toLowerCase().includes(value.toLowerCase()) ||
            x.manufacturingDate?.toLowerCase().includes(value.toLowerCase()) ||
            x.expiryDate?.toLowerCase().includes(value.toLowerCase())
        );
      },
    });
  };

  const fetchSubStock = () => {
    // setLoading(true);
    InventoryInstance.get("subStockManagement/fetchByStoreId/" + storeId).then(
      (res) => {
        setRecords(res.data);
        setFilteredRecords(res.data);

        setLoading(false);
      }
    );
  };

  const fetchNotifications = () => {
    // setLoading(true);
    try {
      InventoryInstance.get(
        "subStockManagement/notifications/" + orgId + "/" + storeId
      ).then((res) => {
        if (res.data) {
          const drugData = res?.data;
          // setBadgeCount(Object.keys(res?.data).length);
          if (drugData?.bufferedDrugsCount && drugData.bufferedDrugsCount > 0) {
            toast.info(
              drugData.bufferedDrugsCount + " drug reached buffer range.",
              { autoClose: 20000 }
            );
          }
          if (drugData?.lessThanSeven && drugData.lessThanSeven > 0) {
            toast.info(
              drugData.lessThanSeven + " drug exipiring within 7 days.",
              { autoClose: 20000 }
            );
          }
          if (drugData?.lessThanFifteen && drugData.lessThanFifteen > 0) {
            toast.info(
              drugData.lessThanFifteen + " drug exipiring within 15 days.",
              { autoClose: 20000 }
            );
          }
          if (drugData?.lessThanThirty && drugData.lessThanThirty > 0) {
            toast.info(
              drugData.lessThanThirty + " drug exipiring within 30 days.",
              { autoClose: 20000 }
            );
          }
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchSubStock();
    fetchNotifications();
  }, []);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(filteredRecords, headCells, filterFn);

  const handleExpiryChange = (event) => {
    const val = +event.target.value;
    setExpiry(val);
    handleFilterRecords("expiry", val);
  };

  const handleBufferRange = (e) => {
    const checked = e.target.checked;
    setIsBufferRange(checked);
    handleFilterRecords("bufferRange", checked);
  };

  const handleFilterRecords = (type, val) => {
    if (type === "expiry") {
      if (!!val) {
        const isDayAfter = moment()
          .add(val, "d")
          .format();
        if (isBufferRange) {
          setFilteredRecords((ps) =>
            ps.filter((x) => moment(x.expiryDate).isBefore(isDayAfter))
          );
        } else {
          setFilteredRecords(
            records.filter((x) => moment(x.expiryDate).isBefore(isDayAfter))
          );
        }
      } else {
        if (isBufferRange) {
          setFilteredRecords(
            records.filter((x) => x.availableQty < parseInt(x.bufferRange))
          );
        } else {
          setFilteredRecords(records);
        }
      }
    } else {
      if (val) {
        setFilteredRecords((ps) =>
          ps.filter((x) => x.availableQty < parseInt(x.bufferRange))
        );
      } else {
        if (!!expiry) {
          const isDayAfter = moment()
            .add(expiry, "d")
            .format();
          setFilteredRecords(
            records.filter((x) => moment(x.expiryDate).isBefore(isDayAfter))
          );
        } else {
          setFilteredRecords(records);
        }
      }
    }
  };
  const [W, H] = [210, 297];
  const margin = 10;

  const style = {
    cellPadding: 1, // a number, array or object (see margin below)
    fontSize: 8,
    font: "helvetica", // helvetica, times, courier
    lineWidth: 0.01,
    lineColor: 127,
    fontStyle: "normal", // normal, bold, italic, bolditalic
    overflow: "linebreak", // visible, hidden, ellipsize or linebreak
    fillColor: false, // false for transparent or a color as described below
    textColor: 0,
    halign: "left", // left, center, right
    valign: "middle", // top, middle, bottom
    cellWidth: "auto", // 'auto', 'wrap' or a number
  };
  const generatePdf = (report, data, staffData) => {
    const org = staffData.orgName;

    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const pdfData = data.map((item) => [
      item.drugTypeName,
      item.drugName,
      item.uomName,
      item.serialNo,
      item.requestedQty,
      item.issuedQty,
      item.availableQty,
      item.pendingQty,
      item.amount,
    ]);

    const pdf = new jsPDF("p", "mm", [W, H], true);
    let Y = margin;
    pdf.addImage(ahmis, "PNG", margin, Y + 2, 15, 15);
    pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
    pdf.setFontSize(14);
    let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
    pdf.text(splitTitle, W / 2, Y, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(report, W / 2, Y + 15, "center");
    Y += 30;
    pdf.setFontSize(10);

    pdf.text(
      `Report generated on: ${now}, ${time}`,
      W - margin,
      Y + 7,
      "right"
    );
    Y += 14;

    pdf.autoTable({
      head: [
        [
          "Drug Type",
          "Drug Name",
          "UOM",
          "Serial No.",
          "Requested Qty",
          "Issued Qty",
          "Pending",
          "Available Qty",
          "Price(₹)",
        ],
      ],
      body: pdfData,

      startY: Y,
      margin,
      headStyles: {
        ...style,
        fillColor: [11, 156, 171],
        textColor: 255,
        lineColor: 255,
        fontSize: 8,
        fontWeight: 500,
      },
      bodyStyles: { ...style },
    });

    pdf.save(`Sub Stock Receipt Report-${now}, ${time}.pdf`);
  };

  const printDocument = (id) => {
    const reportName = "Sub Stock Receipt Report";

    generatePdf(
      reportName,

      records,
      staff
    );
  };
  return (
    <Grid container spacing={2} align="right">
      {/* <Badge badgeContent={badgeCount} color="primary">
        <MailIcon />
      </Badge> */}
      <Grid item lg={12} justifyContent="end">
        <FormControl
          title="Check Stock Buffer Limit"
          style={{ marginRight: 30 }}
          component="fieldset"
        >
          <FormLabel component="legend">Buffer Limit</FormLabel>

          <FormControlLabel
            control={
              <Checkbox
                name="BufferLimit"
                checked={isBufferRange}
                onChange={handleBufferRange}
              />
            }
          />
        </FormControl>

        <FormControl component="fieldset" style={{ marginRight: 30 }}>
          <FormLabel component="legend" align="left">
            Expiry (Days)
          </FormLabel>
          <RadioGroup
            title="Exp. of medicine in Days"
            name="expiry"
            row
            onChange={handleExpiryChange}
            value={expiry}
          >
            <FormControlLabel value={30} control={<Radio />} label="30" />
            <FormControlLabel value={15} control={<Radio />} label="15" />
            <FormControlLabel value={7} control={<Radio />} label="7" />
            <FormControlLabel value={-1} control={<Radio />} label="Expired" />
            <FormControlLabel value={0} control={<Radio />} label="None" />
          </RadioGroup>
        </FormControl>

        <TextField
          placeholder="search"
          variant="outlined"
          size="small"
          label="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <TableContainer>
          {loading && <LinearProgress />}
          <Table size="small">
            <TableHead style={{ backgroundColor: myOwnColor }}>
              <StyledTableRow>
                <StyledTableCell rowSpan={4}>Sl. No.</StyledTableCell>
                <StyledTableCell rowSpan={4}>Drug Type</StyledTableCell>
                <StyledTableCell rowSpan={4}>Drug Name</StyledTableCell>
                <StyledTableCell rowSpan={4}>Units</StyledTableCell>
                <StyledTableCell rowSpan={4}>Serial/Batch No.</StyledTableCell>
                <StyledTableCell rowSpan={4}>Mfg. Date</StyledTableCell>
                <StyledTableCell rowSpan={4}>Exp. Date</StyledTableCell>
                <StyledTableCell colSpan={4} align="center">
                  Quantity
                </StyledTableCell>
                <StyledTableCell rowSpan={4} align="center">
                  Buffer Range
                </StyledTableCell>
                <StyledTableCell rowSpan={4} align="center">
                  Amount(₹)
                </StyledTableCell>
                <StyledTableCell rowSpan={4} align="center">
                  Remarks
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="center">Requested</StyledTableCell>
                <StyledTableCell align="center">Issued</StyledTableCell>
                <StyledTableCell align="center">Pending</StyledTableCell>
                <StyledTableCell align="center">In Stock</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            {recordsAfterPagingAndSorting()?.length !== 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((item) => (
                  <TableRow key={item.id}>
                    <StyledTableCell>{item.srNo}</StyledTableCell>
                    <StyledTableCell>{item.drugTypeName}</StyledTableCell>
                    <StyledTableCell>{item.drugName}</StyledTableCell>
                    <StyledTableCell>{item.units}</StyledTableCell>
                    <StyledTableCell>{item.serialNo}</StyledTableCell>
                    <StyledTableCell>
                      {moment(item.manufacturingDate).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(item.expiryDate).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ whiteSpace: "nowrap" }}
                      align="center"
                    >
                      {item.requestedQty}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ whiteSpace: "nowrap" }}
                      align="center"
                    >
                      {item.issuedQty}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.pendingQty}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.availableQty}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.bufferRange}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.amount}
                    </StyledTableCell>

                    <StyledTableCell>
                      <TextField
                        fullWidth
                        maxRows={2}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        size="small"
                        value={item.remarks}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan={13} align="center">
                    No Records Found
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TblPagination />
        {/* <Button variant="outlined" startIcon={<FontAwesomeIcon
            title="Edit"
            style={{ color: "#f55140", fontSize: "15px" }}
            icon={"file-pdf"}
          />}>{"PDF"}</Button> */}
      </Grid>
    </Grid>
  );
}

export default SubStockManagement;
