import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from "react";

export default function Footer() {

    return (

                <Grid item xs={12} align="center" >
                    <Typography style={{ color: 'white',fontSize:'14px' ,fontFamily:'helvetica', }}>
                        This website is maintained by Ministry of Ayush, Government of India. Designed &amp; developed by <a style={{ textDecoration: 'none' }} target="_blank" href="https://bel-india.in/"><span style={{color:"#5DB5F5"}}>Bharat Electronics Ltd.</span></a>
                        
                </Typography>
                <Typography style={{ color: 'white' ,fontSize:'12px' ,fontFamily:'helvetica'}}>
                Last Updated: Sep 11th, 2024
                {/* , Version: 1.4.3 */}
                </Typography>
                </Grid>
            
        
       
    );
}
